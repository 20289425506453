import React, { useContext } from 'react'
import logo from 'url:../images/nutrien-water.png'
import alertIcon from 'url:../images/002-bell.png'
import formIcon from 'url:../images/001-test.png'
import profileIcon from 'url:../images/003-user.png'
import { useApi } from '../lib/api'
import './Header.scss'
import { Link } from 'react-router-dom'
import { authContext } from '../lib/auth'

const SUBCONTRACTOR_NAV = [{
  name: 'Available Jobs',
  url: '/jobs/available',
  icon: alertIcon
}, {
  name: 'My Jobs',
  url: '/jobs/mine',
  icon: formIcon
}, {
  name: 'My Profile',
  url: '/profile',
  icon: profileIcon
}]

const ADMIN_NAV = [{
  name: 'Jobs',
  url: '/admin/jobs',
  icon: formIcon
}, {
  name: 'Users',
  url: '/admin/users',
  icon: profileIcon
}]

export interface HeaderProps {
  url?: string
}

export function Header(props: HeaderProps) {
  const api = useApi()
  const nav = api.user!.role === 'admin' ? ADMIN_NAV : SUBCONTRACTOR_NAV
  const isActive = (url: string) => props.url === url

  return <div className="page-header">
    <Link to="/">
      <img className="logo" src={logo} alt="Nutien Water" />
    </Link>
    <a onClick={() => api.logout()} className="logout-btn">Logout</a>
    <ul className="nav">
      {nav.map(({ name, url, icon }, i) => (
        <li key={i}>
          <Link className={isActive(url) ? 'active' : ''} to={url}>
            <div className="icon" style={{ backgroundImage: `url(${icon})` }} />{name}
          </Link>
        </li>
      ))}
    </ul>
  </div>
}