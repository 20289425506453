import React, { FormEvent, PureComponent, useCallback, useEffect, useState } from 'react'
import { Job, User, useApi } from '../../lib/api'
import { Spinner } from '../Spinner'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'
import './List.scss'
import { toast } from 'react-toastify'
import { StarRating } from './StarRating'
import { DateTime } from 'luxon'

interface ListProps<T> {
  items: T[]
  id: (item: T) => string
  filterText: (item: T) => string
  columns: Column<T>[]
  filter: string
  setFilter: (filter: string) => void
  page: number
  setPage: (page: number) => void
  itemNoun: string
  clickCreate: () => void
  editItem: (id: string) => void
  editLabel?: (item: T) => string
  deleteItem: (id: string) => void
  actions?: {
    label: string
    enabled: (item: T) => boolean
    callback: (id: string) => void
  }[]
}

interface Column<T> {
  header: string
  value: (item: T) => string | undefined
  content?: (item: T) => JSX.Element | undefined
  links?: (item: T) => { name: string; url: string }[]
  label?: string
  bold?: boolean
}

function List<T>(props: ListProps<T>) {
  const itemsPerPage = 10
  const page = props.page
  const filterLower = props.filter.toLowerCase()
  const filteredItems = props.items.filter(item => props.filterText(item).toLowerCase().includes(filterLower))
  const pages = Math.max(Math.ceil(filteredItems.length / itemsPerPage), 1)
  const items = filteredItems.slice(page * itemsPerPage, (page + 1) * itemsPerPage)

  const setFilter = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      props.setPage(0)
      props.setFilter((event.target as HTMLInputElement).value)
    },
    [props.setFilter, props.setPage]
  )

  return (
    <div className="admin-list">
      <div className="topbar">
        <input type="text" className="search-box" placeholder="Search" value={props.filter} onInput={setFilter} />
        <a className="create-btn" onClick={props.clickCreate}>
          New {props.itemNoun} <span className="fas fa-plus" />
        </a>
      </div>
      <table>
        <tbody>
          <tr key="header">
            {props.columns.map((col, i) => (
              <th key={i}>{col.header}</th>
            ))}
            <th key="actions">Actions</th>
          </tr>
          {items.map(item => (
            <tr key={props.id(item)}>
              {props.columns.map((col, i) => (
                <td className={col.bold ? 'bold' : ''} key={i}>
                  {col.value(item) != undefined ? (
                    <>
                      {col.label && <span className="label">{col.label}</span>}
                      {col.value(item)}
                      {col.links?.(item).map(l => (
                        <a target="_blank" href={l.url}>
                          {l.name}
                        </a>
                      ))}
                      {col.content?.(item)}
                    </>
                  ) : (
                    ''
                  )}
                </td>
              ))}
              <td className="actions" key="actions">
                <a onClick={() => props.editItem(props.id(item))}>{props.editLabel?.(item) ?? 'Edit'}</a>
                <a onClick={() => props.deleteItem(props.id(item))}>Delete</a>
                {props.actions &&
                  props.actions
                    .filter(({ enabled }) => enabled(item))
                    .map(action => <a onClick={() => action.callback(props.id(item))}>{action.label}</a>)}
              </td>
            </tr>
          ))}
          {items.length == 0 && (
            <tr key="none">
              <td className="no-items" colSpan={props.columns.length + 1}>
                No {props.itemNoun}s found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="bottombar">
        <p className="pagination">
          {
            <span onClick={page > 0 ? () => props.setPage(page - 1) : undefined}>
              <i className="fas fa-chevron-left" />
            </span>
          }
          <span>
            Page <strong>{page + 1}</strong> of <strong>{pages}</strong>
          </span>
          {
            <span onClick={page < pages - 1 ? () => props.setPage(page + 1) : undefined}>
              <i className="fas fa-chevron-right" />
            </span>
          }
        </p>
        <a className="create-btn" onClick={props.clickCreate}>
          New {props.itemNoun} <span className="fas fa-plus" />
        </a>
      </div>
    </div>
  )
}

function mapUpload(upload: { slot: string; url: string }) {
  return {
    name: { invoice: 'Invoice', 'job sheet': 'Job Sheet' }[upload.slot] ?? 'Other',
    url: upload.url,
  }
}

export function AdminJobs() {
  const api = useApi()
  const [jobs, setJobs] = useState<Job[] | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [page, setPage] = useQueryParam('page', NumberParam)
  const [filter, setFilter] = useQueryParam('filter', StringParam)
  const history = useHistory()

  const load = () => {
    api
      .getAllJobs()
      .then(jobs => setJobs(jobs))
      .catch(err => setError('Unexpected error occured'))
  }

  useEffect(load, [])

  if (error) return <p className="p-error">Error: {error}</p>
  if (!jobs) return <Spinner margin={40} />

  const cols: Column<Job>[] = [
    {
      header: 'Title',
      value: job => job.title,
      bold: true,
    },
    {
      header: 'Submitted',
      value: job => (job.submittedOn ? DateTime.fromISO(job.submittedOn).toFormat('dd/MM/yyyy') : '--'),
    },
    {
      header: 'Status',
      value: job => job.status.substring(0, 1).toUpperCase() + job.status.substring(1),
    },
    {
      header: 'Contractor',
      value: job => job.contractorId ?? '',
    },
  ]

  const deleteJob = async (id: string) => {
    if (await api.deleteJob(id)) {
      toast.success('Job has been deleted')
      load()
    } else {
      toast.error('Unexpected error occured')
    }
  }

  return (
    <List
      items={jobs}
      id={job => job.id}
      filterText={job => job.title}
      columns={cols}
      page={Math.max((page ?? 1) - 1, 0)}
      setPage={page => setPage(page + 1)}
      filter={filter ?? ''}
      setFilter={setFilter}
      itemNoun="job"
      clickCreate={() => history.push('/admin/jobs/new')}
      editItem={id => history.push(`/admin/jobs/${id}`)}
      editLabel={job => (job.status === 'pending' ? 'Review' : 'Edit')}
      deleteItem={deleteJob}
    />
  )
}

export function AdminUsers() {
  const api = useApi()
  const [users, setUsers] = useState<User[] | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [page, setPage] = useQueryParam('page', NumberParam)
  const [filter, setFilter] = useQueryParam('filter', StringParam)
  const history = useHistory()

  const load = () => {
    api
      .getUsers()
      .then(users => setUsers(users))
      .catch(err => setError('Unexpected error occured'))
  }

  useEffect(load, [])

  if (error) return <p className="p-error">Error: {error}</p>
  if (!users) return <Spinner margin={40} />

  const cols: Column<User>[] = [
    {
      header: 'Name',
      value: user => user.name,
      bold: true,
    },
    {
      header: 'Role',
      value: user => user.role.replace(/^(.)/i, a => a.toUpperCase()),
    },
    {
      header: 'Vendor number',
      value: user => user.subcontractor?.vendorNumber,
    },
    {
      header: 'Avg Rating',
      value: user => '',
      content: user =>
        user.rating ? <StarRating value={user.rating} /> : <span style={{ fontStyle: 'italic' }}>No ratings</span>,
    },
  ]

  const deleteUser = async (id: string) => {
    if (await api.deleteUser(id)) {
      toast.success('User has been deleted')
      load()
    } else {
      toast.error('Unexpected error occured')
    }
  }

  return (
    <List
      items={users}
      id={user => user.id}
      filterText={user => user.name + '__' + user.email}
      columns={cols}
      page={Math.max((page ?? 1) - 1, 0)}
      setPage={page => setPage(page + 1)}
      filter={filter ?? ''}
      setFilter={setFilter}
      itemNoun="user"
      clickCreate={() => history.push('/admin/users/new')}
      editItem={id => history.push(`/admin/users/${id}`)}
      deleteItem={deleteUser}
    />
  )
}
