import React, { FormEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'
import { Time, TimePicker } from '../timepicker/timepicker'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'

const GOOGLE_LIBRARIES: Libraries = ['places']

export function Row(props: { children: ReactNode }) {
  return <div className="input-row">{props.children}</div>
}

interface InputProps<T> {
  label: string
  value: T
  error?: string | undefined | false
  onInput?: (value: T) => void
  onBlur?: () => void
  size?: number
}

type TextInputProps = InputProps<string> & { textarea?: boolean; email?: boolean }

type OptionInputProps = InputProps<string> & { options: string[] }

export function TextInput(props: TextInputProps) {
  const onInput = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onInput?.((ev.target as HTMLInputElement | HTMLTextAreaElement).value)
  }

  const type = props.email ? 'email' : 'text'
  const flexGrow = props.size ?? 1
  const flexBasis = 100 * flexGrow

  return (
    <div className="input" style={{ flexGrow, flexBasis }}>
      <label>
        {props.label}
        {props.error && <span className="error">{props.error}</span>}
        {props.textarea ? (
          <textarea value={props.value} onInput={onInput} />
        ) : (
          <input type={type} value={props.value} onInput={onInput} onBlur={props.onBlur} />
        )}
      </label>
    </div>
  )
}

export function OptionInput(props: OptionInputProps) {
  const onInput = (ev: FormEvent<HTMLSelectElement>) => {
    props.onInput?.((ev.target as HTMLSelectElement).value)
  }

  const flexGrow = props.size ?? 1
  const flexBasis = 100 * flexGrow

  return (
    <div className="input" style={{ flexGrow, flexBasis }}>
      <label>
        {props.label}
        {props.error && <span className="error">{props.error}</span>}
        <select value={props.value} onChange={onInput} onBlur={props.onBlur}>
          {props.options.map((opt, i) => (
            <option key={i}>{opt}</option>
          ))}
        </select>
      </label>
    </div>
  )
}

export function DateInput(props: InputProps<Date | undefined>) {
  return (
    <div className="input">
      <label>
        {props.label}
        {props.error && <span className="error">{props.error}</span>}
        <div>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={props.value}
            onChange={value => props.onInput?.(value as Date | undefined)}
            onBlur={props.onBlur}
          />
        </div>
      </label>
    </div>
  )
}

export function TimeInput(props: InputProps<Time>) {
  return (
    <div className="input">
      <label>
        {props.label}
        {props.error && <span className="error">{props.error}</span>}
        <div style={{ marginTop: 2 }}>
          <TimePicker value={props.value} onInput={props.onInput} />
        </div>
      </label>
    </div>
  )
}

export function AddressInput(props: InputProps<string>) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA5PK3SWRqLTV5G5XNU3uctepII_Z0UrfQ',
    libraries: GOOGLE_LIBRARIES,
  })

  return isLoaded ? (
    <Autocomplete restrictions={{ country: 'AU' }}>
      <TextInput {...props} />
    </Autocomplete>
  ) : (
    <TextInput {...props} />
  )
}
