import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { DateTime } from 'luxon'
import { Job, useApi } from '../lib/api'
import { Spinner } from './Spinner'
import phoneIcon from 'url:../images/phone.png'
import emailIcon from 'url:../images/email.png'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Working } from './Working'
import { Dropzone } from './Dropzone'
import './Job.scss'

function JobView(props: { job: Job; reload: () => void }) {
  const { job } = props
  const api = useApi()
  const history = useHistory()

  const [pendingAction, setPendingAction] = useState<string>()

  const acceptJob = async () => {
    if (pendingAction != undefined) return
    setPendingAction('accepting')
    const result = await api.acceptJob(job.id)
    setPendingAction(undefined)
    if (result) {
      toast.success('You have accepted the job')
      props.reload()
    } else {
      toast.error('An unexpected error occured')
    }
  }

  const completeJob = async () => {
    if (pendingAction != undefined) return
    setPendingAction('completing')
    const result = await api.completeJob(job.id)
    setPendingAction(undefined)
    if (result) {
      props.reload()
    } else {
      toast.error('An unexpected error occured')
    }
  }

  const cancelJob = async () => {
    history.push(`/jobs/${job.id}/cancel`)
  }

  const [uploads, setUploads] = useState<{ slot: string; name: string; id: string }[]>([])
  const setFile = useCallback((slot: string, name: string, id: string) => {
    setUploads(uploads => [...uploads.filter(u => u.slot !== slot), { slot, name, id }])
  }, [])
  const removeFile = (slot: string) => {
    setUploads(uploads.filter(u => u.slot !== slot))
  }
  const canSubmitUploads = job.status === 'completed' && uploads.length == 2

  const submitFiles = async () => {
    if (pendingAction != undefined) return
    setPendingAction('submitting files')
    const result = await api.closeJob(
      job.id,
      uploads.find(u => u.slot === 'job sheet')!.id,
      uploads.find(u => u.slot === 'invoice')!.id
    )
    setPendingAction(undefined)
    if (result) {
      props.reload()
    } else {
      toast.error('An unexpected error occured')
    }
  }

  return (
    <div className="single-job">
      <div className="back-btn-container">
        <Link to={job.myJob ? '/jobs/mine' : ''} className="back-btn">
          <i className="fas fa-chevron-left" />
          {`View all ${job.myJob ? 'my' : 'available'} jobs`}
        </Link>
      </div>
      <div className="job">
        <img src={job.mapUrl} className="desktop-map" />
        <div className="content">
          <div className="heading">
            <h3 className="title">{job.title}</h3>
            <p className={`job-status ${job.status}`}>{job.status}</p>
            {job.customer && (
              <div className="contact-buttons">
                <a href={`tel:${job.customer.phone}`}>
                  <img src={phoneIcon} />
                  Call
                </a>
                {job.customer.email && (
                  <a href={`mailto:${job.customer.email}`}>
                    <img src={emailIcon} />
                    Email
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="summary">
            <img src={job.mapUrl} className="mobile-map" />
            <div className="info">
              <div className="address">
                <h4>Address</h4>
                <p>
                  {job.address.line1}
                  <br />
                  {job.address.suburb.toUpperCase()} {job.address.state} {job.address.postcode}
                </p>
              </div>
              <div className="date">
                <h4>Listed on</h4>
                <p>{DateTime.fromISO(job.time).toFormat("dd/MM/yyyy 'at' h:mma").toLowerCase()}</p>
              </div>
              <div className="job-number">
                <h4>Job number</h4>
                <p>{job.jobNumber}</p>
              </div>
            </div>
          </div>
          <p className="description">{job.description}</p>
          {job.customer && (
            <>
              <h4>Customer details</h4>
              <table>
                <tbody>
                  <tr>
                    <th>Customer name</th>
                    <td>{job.customer.name}</td>
                  </tr>
                  <tr>
                    <th>Email address</th>
                    <td>{job.customer.email}</td>
                  </tr>
                  <tr>
                    <th>Phone number</th>
                    <td>{job.customer.phone}</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <div className="actions">
            {job.canAccept && (
              <a className="btn" onClick={acceptJob}>
                {pendingAction === 'accepting' ? <Working /> : 'Accept job'}
              </a>
            )}
            {job.canComplete && (
              <a className="btn" onClick={cancelJob}>
                Cancel job
              </a>
            )}
            {job.canComplete && (
              <a className="btn" onClick={completeJob}>
                {pendingAction === 'completing' ? <Working /> : 'Job is complete'}
              </a>
            )}
            {canSubmitUploads && (
              <a className="btn" onClick={submitFiles}>
                {pendingAction === 'submitting files' ? <Working /> : 'Submit files'}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export function JobPage() {
  const api = useApi()
  const { id } = useParams<{ id: string }>()
  const [job, setJob] = useState<Job | undefined>()
  const [error, setError] = useState<string | undefined>()

  const load = async () => {
    try {
      const job = await api.getJob(id)
      if (job) {
        setJob(job)
      } else {
        setError('Job not found')
      }
    } catch (err) {
      setError('Unexpected error occured')
    }
  }

  const reload = () => {
    window.scrollTo(0, 0)
    setJob(undefined)
    load()
  }

  useEffect(() => {
    load()
  }, [id])

  if (error) return <p>Error: {error}</p>
  if (!job) return <Spinner margin={40} />

  return <JobView job={job} reload={reload} />
}
