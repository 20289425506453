import React, { useState } from 'react'
import './StarRating.scss'

function Star(props: { filled: number, onClick: () => void }) {
  const { filled, onClick } = props
  const width = `${Math.min(Math.max(filled, 0), 1) * 100}%`
  return <span className="star">
    <i className={`far fa-star`} onClick={onClick} />
    <i className={`fas fa-star`} onClick={onClick} style={{ width }} />
  </span>
}

export function StarRating(props: { value: number | undefined, save?: (value: number) => Promise<any> }) {
  const [stars, setStars] = useState(props.value ?? 0)
  const [saving, setSaving] = useState(false)

  const clickStar = props.save ? (index: number) => {
    setStars(index)
    setSaving(true)
    props.save!(index).then(() => setSaving(false))
  } : () => {}

  return <div className={`star-rating ${props.save != null ? 'edit' : ''}`}>
    {saving ? (
      'Saving...'
    ) : (
      [1, 2, 3, 4, 5].map(i => <Star key={i} filled={stars - (i - 1)} onClick={() => clickStar(i)} />)
    )}
  </div>
}