import React, { useEffect, useState } from 'react'
import { OptionInput, Row, TextInput } from './Input'
import { useHistory, useParams } from 'react-router'
import { Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'
import './EntityForm.scss'
import { useApi, User, UserInput } from '../../lib/api'
import { toast } from 'react-toastify'
import { Spinner } from '../Spinner'
import { Subcontractor } from '../../../../server/src/models/User'

const ROLES = ['Subcontractor', 'Administrator']

const schema = yup.object().shape({
  name: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  role: yup.string().required('Required'),
  phone: yup.string(),
  subcontractor: yup
    .object()
    .shape({
      vendorNumber: yup.string(),
      companyName: yup.string(),
      abn: yup.string(),
    })
    .when('role', {
      is: 'Subcontractor',
      then: yup.object().shape({
        vendorNumber: yup.string().required('Required'),
        companyName: yup.string().required('Required'),
        abn: yup.string().required('Required'),
      }),
    }),
})

interface UserForm {
  name: string
  email: string
  phone: string
  role: string
  subcontractor: Subcontractor
}

const blankForm: UserForm = {
  name: '',
  email: '',
  phone: '',
  role: 'Subcontractor',
  subcontractor: {
    vendorNumber: '',
    companyName: '',
    abn: '',
  },
}

function UserForm(props: { user?: User }) {
  const history = useHistory()
  const api = useApi()

  const initialForm: UserForm = props.user
    ? {
        name: props.user.name,
        email: props.user.email,
        phone: props.user.phone ?? '',
        role: {
          subcontractor: 'Subcontractor',
          admin: 'Administrator',
        }[props.user.role]!,
        subcontractor: props.user.subcontractor ?? blankForm.subcontractor,
      }
    : blankForm

  const onSubmit = async (form: UserForm, h: FormikHelpers<UserForm>) => {
    const data: UserInput = {
      name: form.name,
      email: form.email,
      phone: form.phone,
      role: {
        Subcontractor: 'subcontractor' as 'subcontractor',
        Administrator: 'admin' as 'admin',
      }[form.role]!,
      subcontractor: form.role == 'Subcontractor' ? form.subcontractor : undefined,
    }
    if (props.user) {
      const success = await api.editUser(props.user.id, data)
      h.setSubmitting(false)
      if (success) {
        toast.success('User updated')
        history.push(`/admin/users`)
      } else {
        toast.error('An unexpected server error occured')
      }
    } else {
      const id = await api.createUser(data)
      h.setSubmitting(false)
      if (id) {
        h.resetForm()
        toast.success('User created')
        history.push(`/admin/users/${id}`)
      } else {
        toast.error('An unexpected server error occured')
      }
    }
  }

  return (
    <Formik initialValues={initialForm} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, setFieldValue, errors, touched, handleSubmit, isSubmitting }) => (
        <>
          <form className="entity-form" onSubmit={handleSubmit}>
            <div className="body">
              <p className="form-title">{props.user ? `Editing user "${props.user.name}"` : `Creating a new user`}</p>
              <h1>{values.name.trim() || <span className="empty">Untitled</span>}</h1>
              <Row>
                <TextInput
                  label="Name"
                  value={values.name}
                  onInput={value => setFieldValue('name', value)}
                  error={touched.name && errors.name}
                />
              </Row>
              <Row>
                <TextInput
                  label="Email"
                  value={values.email}
                  onInput={value => setFieldValue('email', value)}
                  error={touched.email && errors.email}
                  email
                />
              </Row>
              <Row>
                <TextInput
                  label="Phone"
                  value={values.phone}
                  onInput={value => setFieldValue('phone', value)}
                  error={touched.phone && errors.phone}
                />
              </Row>
              <Row>
                <OptionInput
                  label="User type"
                  value={values.role}
                  onInput={value => setFieldValue('role', value)}
                  error={touched.role && errors.role}
                  options={ROLES}
                />
              </Row>
              {values.role == 'Subcontractor' && (
                <>
                  <Row>
                    <TextInput
                      label="Vendor ID"
                      value={values.subcontractor.vendorNumber}
                      onInput={value => setFieldValue('subcontractor.vendorNumber', value)}
                      error={touched.subcontractor?.vendorNumber && errors.subcontractor?.vendorNumber}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      label="Company name"
                      value={values.subcontractor.companyName}
                      onInput={value => setFieldValue('subcontractor.companyName', value)}
                      error={touched.subcontractor?.companyName && errors.subcontractor?.companyName}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      label="ABN"
                      value={values.subcontractor.abn}
                      onInput={value => setFieldValue('subcontractor.abn', value)}
                      error={touched.subcontractor?.abn && errors.subcontractor?.abn}
                    />
                  </Row>
                </>
              )}
            </div>
            <div className="buttons">
              <a onClick={() => history.push('/admin/users')} className="btn outline">
                Cancel
              </a>
              <button disabled={isSubmitting} type="submit" className="btn">
                {props.user ? 'Update' : 'Save'}
              </button>
            </div>
          </form>
        </>
      )}
    </Formik>
  )
}

function UserFormContainer() {
  const { id } = useParams<{ id: string }>()
  const [user, setUser] = useState<User | undefined>()
  const [error, setError] = useState<string | undefined>()
  const api = useApi()

  const load = async () => {
    try {
      const user = await api.getUser(id)
      if (user) {
        setUser(user)
      } else {
        setError('User not found')
      }
    } catch (err) {
      setError('Unexpected error occured')
    }
  }

  useEffect(() => {
    if (id !== 'new') load()
  }, [id])

  if (id == 'new') {
    return <UserForm />
  } else {
    if (error) return <p>Error: {error}</p>
    if (!user) return <Spinner margin={40} />

    return <UserForm user={user} />
  }
}

export { UserFormContainer as UserForm }
