import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { Job, useApi } from '../lib/api'
import { Spinner } from './Spinner'
import './Job.scss'
import phoneIcon from 'url:../images/phone.png'
import emailIcon from 'url:../images/email.png'
import { Link } from 'react-router-dom'

function truncateText(str: string, len: number) {
  return str.length > len ? str.substr(0, len) + '...' : str
}

function Jobs(props: { title: string, jobs: Job[] }) {
  return <div className="jobs">
    <h1>{props.title}</h1>
    {props.jobs.map(job => (
      <div className="job" key={job.id}>
        <img src={job.mapUrl} className="desktop-map" />
        <div className="content">
          <div className="heading">
            <Link className="title" to={`/jobs/${job.id}`}>
              <h3>{job.title}</h3>
            </Link>  
            {job.status !== 'available' && (
              <p className={`status ${job.status}`}>{job.status}</p>
            )}
            {job.customer && <div className="contact-buttons">
              <a href={`tel:${job.customer.phone}`}><img src={phoneIcon} />Call</a>
              <a href={`mailto:${job.customer.email}`}><img src={emailIcon} />Email</a>
            </div>}
          </div>
          <div className="summary">
            <img src={job.mapUrl} className="mobile-map" />
            <div className="info">
              <div className="address">
                <h4>Address</h4>
                <p>
                  {job.address.line1}<br/>
                  {job.address.suburb.toUpperCase()} {job.address.state} {job.address.postcode}
                </p>
              </div>
              <div className="date">
                <h4>Date</h4>
                <p>{DateTime.fromISO(job.time).toFormat('dd/MM/yyyy')}</p>
              </div>
              <div className="time">
                <h4>Time</h4>
                <p>{DateTime.fromISO(job.time).toFormat('h:mma').toLowerCase()}</p>
              </div>
            </div>
          </div>
          <p className="description">{truncateText(job.description, 200)}</p>
          <div className="actions">
            <Link className="btn" to={`/jobs/${job.id}`}>View job</Link>
          </div>
        </div>
      </div>
    ))}
  </div>
}

export function AvailableJobs() {
  const api = useApi()
  const [jobs, setJobs] = useState<Job[] | undefined>()
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    api.getAvailableJobs()
      .then(jobs => setJobs(jobs))
      .catch(err => setError('Unexpected error occured'))
  }, [])

  if (error) return <p className="p-error">Error: {error}</p>
  if (!jobs) return <Spinner margin={40} />
  if (jobs.length == 0) {
    return <p className="list-empty">There are no available jobs right now.</p>
  }

  return <Jobs title="Available Jobs" jobs={jobs} />
}

export function MyJobs() {
  const api = useApi()
  const [jobs, setJobs] = useState<Job[] | undefined>()
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    api.getMyJobs()
      .then(jobs => setJobs(jobs))
      .catch(err => setError('Unexpected error occured'))
  }, [])

  if (error) return <p className="p-error">Error: {error}</p>
  if (!jobs) return <Spinner margin={40} />
  if (jobs.length == 0) {
    return <p className="list-empty">You have not accepted any jobs.</p>
  }

  return <Jobs title="Your Jobs" jobs={jobs} />
}