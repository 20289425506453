import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { Redirect, Route as BaseRoute, RouteProps } from 'react-router'
import { toast } from 'react-toastify'

interface AuthSession {
  id: string
  name: string
  email: string
  token: string
  role: 'admin' | 'subcontractor'
}

export interface Auth {
  user?: AuthSession
  setUser(user?: AuthSession): void
  removeUser(): void
}

export const authContext = createContext<Auth>({
  setUser() {},
  removeUser() {}
})

export function ProvideAuth(props: { children: ReactNode }) {
  const [user, setUser] = useState<AuthSession | undefined>(() => {
    const item = localStorage.getItem('user')
    if (!item) return undefined
    try {
      return JSON.parse(item)
    }
    catch (err) {
      return undefined
    }
  })
  
  const auth = {
    user,
    setUser(session: AuthSession) {
      localStorage.setItem('user', JSON.stringify(session))
      setUser(session)
    },
    removeUser() {
      localStorage.removeItem('user')
      setUser(undefined)
    }
  }

  return (
    <authContext.Provider value={auth}>
      {props.children}
    </authContext.Provider>
  )
}

export function Route({ children, isPublic, ...rest }: RouteProps & { isPublic?: boolean }) {
  let { user } = useContext(authContext)

  useEffect(() => {
    if (!user && !isPublic) {
      if (localStorage.getItem('loggedOut') !== 'true') {
        toast.error('You need to login', { autoClose: 2000 })
      } else {
        toast.info('You have now logged out')
      }
      localStorage.removeItem('loggedOut')
    }
  }, [user, isPublic])

  if (user || isPublic) {
    return <BaseRoute {...rest}>{children}</BaseRoute>
  } else {
    const render = ({ location }: any) => {
      const to = {
        pathname: '/login',
        state: { from: location }
      }
      return <Redirect to={to} />
    }
    return <BaseRoute {...rest} render={render} />
  }
}