import React, { useEffect, useState } from 'react'

export function Working() {
  const [dots, setDots] = useState(3)

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots + 1) % 4)
    }, 400)
    return () => clearInterval(interval)
  })

  return <span style={{ display: 'inline-block', width: '5.5em', textAlign: 'left' }}>
    {`Working ${'.'.repeat(dots)}`}
  </span>
}