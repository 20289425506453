import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { DateTime } from 'luxon'
import { Job, useApi } from '../lib/api'
import { Spinner } from './Spinner'
import phoneIcon from 'url:../images/phone.png'
import emailIcon from 'url:../images/email.png'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Working } from './Working'
import { Dropzone } from './Dropzone'
import './CancelJob.scss'

export function CancelJobView(props: { job: Job }) {
  const { job } = props
  
  const history = useHistory()
  const api = useApi()
  const [pendingAction, setPendingAction] = useState<string>()
  
  const goBack = () => history.push(`/jobs/${job.id}`)

  const cancelJob = async () => {
    if (pendingAction != undefined) return
    const reason = document.querySelector<HTMLTextAreaElement>('#cancel-explanation')!.value.trim()
    if (reason == '') {
      return toast.error('Please provide an explanation for cancellation')
    }
    setPendingAction('cancelling')
    const result = await api.cancelJob(job.id, reason)
    setPendingAction(undefined)
    if (result) {
      toast.success('The job has been cancelled')
      history.push('/')
    } else {
      toast.error('An unexpected error occured')
    }
  }

  return <div className="page-container">
    <div className="cancel-job">
      <h1>Cancel job</h1>
      <p>You are about to cancel the job "{job.title}".</p>
      <p>Please provide an explanation below for why the job cannot be completed.</p>
      <textarea id="cancel-explanation" />
      <div className="actions">
        <a className="btn" onClick={goBack}>Go back</a>
        <a className="btn" onClick={cancelJob}>
          {pendingAction === 'cancelling' ? <Working /> : 'Cancel job'}
        </a>
      </div>
    </div>
  </div>
}

export function CancelJob() {
  const api = useApi()
  const { id } = useParams<{ id: string }>()
  const [job, setJob] = useState<Job | undefined>()
  const [error, setError] = useState<string | undefined>()

  const load = async () => {
    try {
      const job = await api.getJob(id)
      if (job) {
        setJob(job)
      } else {
        setError('Job not found')
      }
    } catch (err) {
      setError('Unexpected error occured')
    }
  }

  const reload = () => {
    window.scrollTo(0, 0)
    setJob(undefined)
    load()
  }

  useEffect(() => { load() }, [id])

  if (error) return <p>Error: {error}</p>
  if (!job) return <Spinner margin={40} />

  return <CancelJobView job={job} />
}