import React, { FocusEvent, Fragment, KeyboardEvent, useEffect, useRef, useState } from 'react';
import './timepicker.css'

export interface Time {
  hour?: number
  minute?: number
  second?: number
  pm?: boolean
}

export interface TimePickerProps {
  value: Time
  onInput?: (value: Time) => void
  className?: string
  seconds?: boolean
}

export function TimePicker(props: TimePickerProps) {
  const [buffer, setBuffer] = useState<{ idx: Number, str: string } | undefined>()
  const { hour, minute, second, pm } = props.value
  const setHour = (hour?: number) => props.onInput?.({ ...props.value, hour })
  const setMinute = (minute?: number) => props.onInput?.({ ...props.value, minute })
  const setSecond = (second?: number) => props.onInput?.({ ...props.value, second })
  const setPm = (pm?: boolean) => props.onInput?.({ ...props.value, pm })

  const ref = useRef<HTMLDivElement | null>(null)

  const onKeyPress = (event: KeyboardEvent, idx: number) => {
    if (idx == 3) {
      if (['a', 'p'].indexOf(event.key.toLowerCase()) != -1) {
        setPm(event.key.toLowerCase() == 'p')
      }
    }
    else if (/\d/.test(event.key)) {
      const newBuffer = (buffer?.idx === idx ? buffer.str : '') + event.key
      const newInput = parseInt(newBuffer)
      if (idx == 0) {
        if (newInput < 1 || newInput > 12) return
      } else {
        if (newInput > 59) return
      }
      switch (idx) {
        case 0: setHour(newInput); break;
        case 1: setMinute(newInput); break;
        case 2: setMinute(newInput); break;
      }
      setBuffer({ idx, str: newBuffer })
      let moveNext = false
      switch (idx) {
        case 0: if (newInput > 1) moveNext = true; break;
        case 1: if (newInput > 5) moveNext = true; break;
        case 2: if (newInput > 5) moveNext = true; break;
      }
      if (newBuffer == '00') moveNext = true
      if (moveNext) {
        let newIdx = idx + 1
        if (newIdx == 2 && props.seconds !== true) {
          newIdx = 3
        }
        ref.current!.querySelector<HTMLElement>(`.idx${newIdx}`)!.focus()
        setBuffer(undefined)
      }
    }
  }

  const onKeyDown = (event: KeyboardEvent, idx: number) => {
    if (event.key == 'Backspace') {
      switch (idx) {
        case 0: setHour(undefined); break;
        case 1: setMinute(undefined); break;
        case 2: setSecond(undefined); break;
        case 3: setPm(undefined); break;
      }
      setBuffer(undefined)
    }
    else if (event.key == 'ArrowLeft' && idx > 0) {
      event.preventDefault();
      let newIdx = idx - 1
      if (newIdx == 2 && props.seconds !== true) {
        newIdx = 1
      }
      ref.current!.querySelector<HTMLElement>(`.idx${newIdx}`)!.focus()
      setBuffer(undefined)
    }
    else if (event.key == 'ArrowRight' && idx < 3) {
      event.preventDefault();
      let newIdx = idx + 1
      if (newIdx == 2 && props.seconds !== true) {
        newIdx = 3
      }
      ref.current!.querySelector<HTMLElement>(`.idx${newIdx}`)!.focus()
      setBuffer(undefined)
    }
  }

  const onBlur = (ev: FocusEvent, idx: number) => {
    if (buffer?.idx === idx) setBuffer(undefined)
  }

  const parts = props.seconds ? [0, 1, 2, 3] : [0, 1, 3]

  const formatNumber = (value: number | undefined) => {
    if (value === undefined) return '--'
    if (value < 10) return '0' + value.toString()
    return value.toString()
  }

  const formatPm = (pm: boolean | undefined) => {
    if (pm === false) return 'AM'
    if (pm === true) return 'PM'
    return '--'
  }

  return <div className={props.className} ref={ref}>
    {parts.map(idx => <Fragment key={idx}>
      {(idx == 1 || idx == 2) && <span className="timepicker-js__span">:</span>}
      <div
        tabIndex={0}
        className={`timepicker-js__input idx${idx}`}
        onKeyPress={ev => onKeyPress(ev, idx)}
        onKeyDown={ev => onKeyDown(ev, idx)}
        onBlur={ev => onBlur(ev, idx)}>
          {idx == 0 && formatNumber(hour)}
          {idx == 1 && formatNumber(minute)}
          {idx == 2 && formatNumber(second)}
          {idx == 3 && formatPm(pm)}
      </div>
    </Fragment>)}
  </div>
}