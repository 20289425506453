import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router'
import logo from 'url:../images/nutrien-water.png'
import './Login.scss'
import { useApi } from '../lib/api'
import { DEBUG_AUTOLOGIN } from '../../env'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

interface LoginState {
  error: boolean
  message: string
}

export function Login() {
  const api = useApi()
  const ref = useRef<HTMLFormElement>(null)
  const [state, setState] = useState<LoginState | undefined>()

  const onSubmit = async (ev: FormEvent) => {
    ev.preventDefault()
    setState({ error: false, message: 'Logging in' })
    const email = (ref.current!.querySelector('input[name="email"]') as HTMLInputElement).value
    const password = (ref.current!.querySelector('input[name="password"]') as HTMLInputElement).value
    const result = await api.login(email, password)
    if (!result.success) {
      setState({ error: true, message: result.reason })
    }
  }

  useEffect(() => {
    if (DEBUG_AUTOLOGIN) {
      api.login(DEBUG_AUTOLOGIN, 'password')
      // api.login('contractor@nutrien.com.au', 'password')
    }
  }, [])

  if (api.user) {
    return <Redirect to="/" />
  }

  return <div className="login-page">
    <img className="logo" src={logo} alt="Nutien Water" />
    <form onSubmit={onSubmit} ref={ref}>
      <input type="email" name="email" placeholder="Email Address" />
      <input type="password" name="password" placeholder="Password" />
      <input type="submit" value="Login" />
      <p className={state?.error ? 'error' : ''}>{state?.message}</p>
      <p><Link to="/password-reset">Forgot password</Link></p>
    </form>
  </div>
}

export function ForgotPassword() {
  const api = useApi()
  const ref = useRef<HTMLFormElement>(null)
  const [state, setState] = useState('')

  const onSubmit = async (ev: FormEvent) => {
    ev.preventDefault()
    setState('Submitting')
    const email = (ref.current!.querySelector('input[name="email"]') as HTMLInputElement).value.trim()
    if (email == '') {
      return setState('Please enter an email address')
    }
    await api.resetPassword(email)
    setState('done')
  }

  if (state == 'done') {
    return <div className="login-page">
      <img className="logo" src={logo} alt="Nutien Water" />
      <form onSubmit={onSubmit} ref={ref}>
        <h3>Password reset submitted</h3>
        <p>
          If the email address entered matches an existing account,
          and email has been sent to that address with a password reset link.
        </p>
        <p><Link to="/login">&larr; Back to login</Link></p>
      </form>
    </div>
  }

  return <div className="login-page">
    <img className="logo" src={logo} alt="Nutien Water" />
    <form onSubmit={onSubmit} ref={ref}>
      <h3>Password reset</h3>
      <p>
        Enter your email address below to reset your password.
      </p>
      <input type="email" name="email" placeholder="Email Address" />
      <input type="submit" value="Reset password" />
      <p>{state}</p>
      <p><Link to="/login">&larr; Back to login</Link></p>
    </form>
  </div>
}

function parseResetUrl(data: string): { email: string, token: string } | undefined {
  try {
    const parsed = atob(data)
    const parts = parsed.split(' ')
    if (parts.length !== 2) return undefined
    return { email: parts[0], token: parts[1] }
  }
  catch (err) {
    return undefined
  }
}

export function PasswordReset() {
  const { data } = useParams<{ data: string }>()
  const history = useHistory()
  const api = useApi()
  const ref = useRef<HTMLFormElement>(null)
  const [state, setState] = useState<LoginState | undefined>()

  const reset = parseResetUrl(data)
  if (!reset) {
    return <p>Invalid token</p>
  }

  const onSubmit = async (ev: FormEvent) => {
    ev.preventDefault()
    setState({ error: false, message: 'Submitting' })
    const password = (ref.current!.querySelector('input[name="password"]') as HTMLInputElement).value
    const password2 = (ref.current!.querySelector('input[name="password2"]') as HTMLInputElement).value
    if (password.length < 8) {
      return setState({ error: true, message: 'Password must be at least 8 characters' })
    }
    if (password !== password2) {
      return setState({ error: true, message: 'Passwords do not match' })
    }
    const result = await api.completePasswordReset(reset.email, password, reset.token)
    if (result.success) {
      toast.success('Password successfully reset')
      history.push('/login')
    } else {
      return setState({ error: true, message: result.message })
    }
  }

  return <div className="login-page">
    <img className="logo" src={logo} alt="Nutien Water" />
    <form onSubmit={onSubmit} ref={ref}>
      <h3>Password reset</h3>
      <p>
        Enter a new password for <strong>{reset.email}</strong>
      </p>
      <input type="password" name="password" placeholder="Type password" />
      <input type="password" name="password2" placeholder="Type password again" />
      <input type="submit" value="Reset password" />
      <p className={state?.error ? 'error' : ''}>{state?.message}</p>
    </form>
  </div>
}