import { Address, Customer } from './models/Job'

export interface Job {
  id: string
  title: string
  description: string
  address: Address
  customer?: Customer
  jobNumber: string
  time: string
  mapUrl: string
  accepted: boolean
  canAccept: boolean
  canComplete: boolean
  myJob: boolean
  status: JobStatus
  uploads?: { slot: string; url: string }[]
  contractorId?: string
  rating?: number
}

export type JobStatus = 'pending' | 'available' | 'accepted' | 'overdue' | 'completed' | 'closed'

export interface User {
  id: string
  name: string
  email: string
  phone?: string
  role: 'subcontractor' | 'admin'
  subcontractor?: Subcontractor
  rating?: number
}

export interface Subcontractor {
  vendorNumber: string
  companyName: string
  abn: string
}
