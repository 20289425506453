import React, { useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route as BaseRoute
} from 'react-router-dom'
import { Header } from './Header'
import { AvailableJobs, MyJobs } from './Jobs'
import { authContext, ProvideAuth, Route } from '../lib/auth'
import './App.scss'
import { ForgotPassword, Login, PasswordReset } from './Login'
import { Profile } from './Profile'
import { JobPage } from './Job'
import { AdminJobs, AdminUsers } from './admin/List'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '@fortawesome/fontawesome-free/css/all.css'
import { QueryParamProvider } from 'use-query-params'
import { NeedHelp } from './NeedHelp'
import { JobForm } from './admin/JobForm'
import { UserForm } from './admin/UserForm'
import { CancelJob } from './CancelJob'

export function App() {
  return (
    <ProvideAuth>
      <Router>
        <QueryParamProvider ReactRouterRoute={BaseRoute}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route isPublic path="/login" exact>
              <Login />
            </Route>
            <Route isPublic path="/password-reset" exact>
              <ForgotPassword />
            </Route>
            <Route isPublic path="/password-reset/:data" exact>
              <PasswordReset />
            </Route>
            <Route path="/jobs/available" exact>
              <Header url="/jobs/available" />
              <AvailableJobs />
              <NeedHelp />
            </Route>
            <Route path="/jobs/mine" exact>
              <Header url="/jobs/mine" />
              <MyJobs />
              <NeedHelp />
            </Route>
            <Route path="/jobs/:id" exact>
              <Header />
              <JobPage />
              <NeedHelp />
            </Route>
            <Route path="/jobs/:id/cancel" exact>
              <Header />
              <CancelJob />
              <NeedHelp />
            </Route>
            <Route path="/profile" exact>
              <Header url="/profile" />
              <Profile />
              <NeedHelp />
            </Route>
            <Route path="/admin/jobs" exact>
              <Header url="/admin/jobs" />
              <AdminJobs />
            </Route>
            <Route path="/admin/jobs/:id" exact>
              <Header url="/admin/jobs" />
              <JobForm />
            </Route>
            <Route path="/admin/users" exact>
              <Header url="/admin/users" />
              <AdminUsers />
            </Route>
            <Route path="/admin/users/:id" exact>
              <Header url="/admin/users" />
              <UserForm />
            </Route>
            <Route isPublic path="*">
              <Header />
              <h3 style={{ textAlign: 'center', margin: '40px 0' }}>404 - Not found</h3>
            </Route>
          </Switch>
        </QueryParamProvider>
      </Router>
      <ToastContainer
        hideProgressBar
        position="bottom-center" />
    </ProvideAuth>
  )
}

function Home() {
  const role = useContext(authContext).user?.role
  if (role === 'admin') {
    return <Redirect to="/admin/jobs" />
  }
  if (role === 'subcontractor') {
    return <Redirect to="/jobs/available" />
  }
  return <Redirect to="/login" />
}