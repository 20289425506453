import React, { useEffect, useState } from 'react'
import { DateInput, OptionInput, Row, TextInput, TimeInput } from './Input'
import { useHistory, useParams } from 'react-router'
import { Time } from '../timepicker/timepicker'
import { Formik, FormikHelpers } from 'formik'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import _ from 'lodash'
import './EntityForm.scss'
import { Job, useApi } from '../../lib/api'
import { toast } from 'react-toastify'
import { Spinner } from '../Spinner'

const STATES = ['WA', 'NSW', 'VIC', 'TAS', 'QLD', 'NT', 'SA', 'ACT'].sort()

const schema = yup.object().shape({
  title: yup.string().required('Required'),
  jobNumber: yup.string().required('Required'),
  customerName: yup.string().required('Required'),
  customerPhone: yup.string().required('Required'),
  customerEmail: yup.string(),
  address: yup.string().required('Required'),
  suburb: yup.string().required('Required'),
  state: yup.string().required('Required'),
  postcode: yup.string().required('Required'),
  date: yup.date().required('Required').nullable(),
  time: yup.object().required('Required').test({
    test: isValidTime,
    message: 'Required',
  }),
})

interface JobForm {
  title: string
  jobNumber: string
  customerName: string
  customerPhone: string
  customerEmail: string
  address: string
  suburb: string
  state: string
  postcode: string
  date: Date | undefined
  time: Time
  description: string
}

const blankForm: JobForm = {
  title: '',
  jobNumber: '',
  customerName: '',
  customerPhone: '',
  customerEmail: '',
  address: '',
  suburb: '',
  state: 'WA',
  postcode: '',
  ...splitDatetime(DateTime.now()),
  description: '',
}

function isValidTime(time: Time) {
  return time.hour != undefined && time.minute != undefined && time.pm != undefined
}

function createDatetime(date: Date, time: Time) {
  return DateTime.fromJSDate(date).set({
    hour: time.hour! + (time.pm !== (time.hour == 12) ? 12 : 0),
    minute: time.minute,
    second: time.second ?? 0,
    millisecond: 0,
  })
}

function splitDatetime(datetime: DateTime): { date: Date; time: Time } {
  return {
    date: datetime.startOf('day').toJSDate(),
    time: {
      hour: datetime.hour % 12,
      minute: datetime.minute,
      pm: datetime.hour >= 12,
    },
  }
}

function JobForm(props: { job?: Job; reload?: () => void }) {
  const history = useHistory()
  const api = useApi()

  const initialForm: JobForm = props.job
    ? {
        title: props.job.title,
        jobNumber: props.job.jobNumber,
        address: props.job.address.line1,
        suburb: props.job.address.suburb,
        state: props.job.address.state,
        postcode: props.job.address.postcode,
        customerName: props.job.customer?.name ?? '',
        customerEmail: props.job.customer?.email ?? '',
        customerPhone: props.job.customer?.phone ?? '',
        ...splitDatetime(DateTime.fromISO(props.job.time)),
        description: props.job.description,
      }
    : blankForm

  const onSubmit = async (form: JobForm, h: FormikHelpers<JobForm>) => {
    const data = {
      title: form.title,
      jobNumber: form.jobNumber,
      customer: {
        name: form.customerName,
        phone: form.customerPhone,
        email: form.customerEmail,
      },
      address: {
        line1: form.address,
        suburb: form.suburb,
        state: form.state,
        postcode: form.postcode,
        country: 'Australia',
      },
      time: createDatetime(form.date!, form.time).toISO(),
      description: form.description,
    }
    if (props.job) {
      const success = await api.editJob(props.job.id, data)
      h.setSubmitting(false)
      if (success) {
        if (props.job.status === 'pending') {
          toast.success('Job has been accepted')
        } else {
          toast.success('Job updated')
        }
        history.push(`/admin/jobs`)
      } else {
        toast.error('An unexpected server error occured')
      }
    } else {
      const id = await api.createJob(data)
      h.setSubmitting(false)
      if (id) {
        h.resetForm()
        toast.success('Job created')
        history.push(`/admin/jobs/${id}`)
      } else {
        toast.error('An unexpected server error occured')
      }
    }
  }

  const onReject = async (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (!props.job) return
    if (await api.deleteJob(props.job.id)) {
      toast.info('Job has been deleted')
      history.push(`/admin/jobs`)
    } else {
      toast.error('An unexpected server error occured')
    }
  }

  const status = props.job?.status ?? 'Draft'

  return (
    <Formik initialValues={initialForm} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, setFieldValue, errors, touched, handleSubmit, isSubmitting }) => (
        <>
          <form className="entity-form" onSubmit={handleSubmit}>
            <div className="body">
              <p className="form-title">{props.job ? `Editing job "${props.job.title}"` : `Creating a new job`}</p>
              <h1>
                {values.title.trim() || <span className="empty">Untitled</span>}
                <p className={`job-status ${status}`}>{status}</p>
              </h1>
              <Row>
                <TextInput
                  label="Summary"
                  value={values.title}
                  onInput={value => setFieldValue('title', value)}
                  error={touched.title && errors.title}
                />
              </Row>
              <Row>
                <TextInput
                  label="Job number"
                  value={values.jobNumber}
                  onInput={value => setFieldValue('jobNumber', value)}
                  error={touched.jobNumber && errors.jobNumber}
                />
              </Row>
              <Row>
                <TextInput
                  label="Customer name"
                  value={values.customerName}
                  onInput={value => setFieldValue('customerName', value)}
                  error={touched.customerName && errors.customerName}
                />
              </Row>
              <Row>
                <TextInput
                  label="Customer phone"
                  value={values.customerPhone}
                  onInput={value => setFieldValue('customerPhone', value)}
                  error={touched.customerPhone && errors.customerPhone}
                />
                <TextInput
                  label="Customer email"
                  value={values.customerEmail}
                  onInput={value => setFieldValue('customerEmail', value)}
                  error={touched.customerEmail && errors.customerEmail}
                  email
                />
              </Row>
              <Row>
                <TextInput
                  label="Address"
                  value={values.address}
                  onInput={value => setFieldValue('address', value)}
                  error={touched.address && errors.address}
                />
              </Row>
              <Row>
                <TextInput
                  size={2}
                  label="Suburb"
                  value={values.suburb}
                  onInput={value => setFieldValue('suburb', value)}
                  error={touched.suburb && errors.suburb}
                />
                <OptionInput
                  label="State"
                  value={values.state}
                  onInput={value => setFieldValue('state', value)}
                  error={touched.state && errors.state}
                  options={STATES}
                />
                <TextInput
                  label="Postcode"
                  value={values.postcode}
                  onInput={value => setFieldValue('postcode', value)}
                  error={touched.postcode && errors.postcode}
                />
              </Row>
              <Row>
                <DateInput
                  label="Listed Date"
                  value={values.date}
                  onInput={value => setFieldValue('date', value)}
                  error={touched.date && errors.date}
                />
                <TimeInput
                  label="Listed Time"
                  value={values.time}
                  onInput={value => setFieldValue('time', value)}
                  error={touched.time && (errors.time as string)}
                />
              </Row>
              <Row>
                <TextInput
                  label="Job description"
                  value={values.description}
                  onInput={value => setFieldValue('description', value)}
                  textarea
                />
              </Row>
            </div>
            <div className="buttons">
              <a onClick={() => history.push('/admin/jobs')} className="btn outline">
                Cancel
              </a>
              {props.job?.status === 'pending' ? (
                <>
                  <button disabled={isSubmitting} onClick={onReject} className="btn red">
                    Delete
                  </button>
                  <button disabled={isSubmitting} type="submit" className="btn">
                    Approve
                  </button>
                </>
              ) : (
                <button disabled={isSubmitting} type="submit" className="btn">
                  {props.job ? 'Update' : 'Save'}
                </button>
              )}
            </div>
          </form>
        </>
      )}
    </Formik>
  )
}

function JobFormContainer() {
  const { id } = useParams<{ id: string }>()
  const [job, setJob] = useState<Job | undefined>()
  const [error, setError] = useState<string | undefined>()
  const api = useApi()

  const load = async () => {
    try {
      const job = await api.getJob(id)
      if (job) {
        setJob(job)
      } else {
        setError('Job not found')
      }
    } catch (err) {
      setError('Unexpected error occured')
    }
  }

  useEffect(() => {
    if (id !== 'new') load()
  }, [id])

  if (id == 'new') {
    return <JobForm />
  } else {
    if (error) return <p>Error: {error}</p>
    if (!job) return <Spinner margin={40} />

    return <JobForm job={job} reload={load} />
  }
}

export { JobFormContainer as JobForm }
