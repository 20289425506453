import React, { useContext, useEffect, useState } from 'react'
import { User } from '../../../server/src/types'
import { useApi } from '../lib/api'
import './Profile.scss'
import { Spinner } from './Spinner'

export function Profile() {
  const api = useApi()

  const [profile, setProfile] = useState<User>()
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    api
      .getProfile()
      .then(setProfile)
      .catch(err => setError('Unexpected error occured'))
  }, [])

  if (error) return <p>Error: {error}</p>
  if (!profile) return <Spinner margin={40} />

  const field = (name: string, value: string) => (
    <div className="field">
      <h4>{name}</h4>
      <p>{value}</p>
    </div>
  )

  return (
    <>
      <h1>Your profile</h1>
      <div className="profile-page">
        <h2>{profile.name}</h2>
        <h3>Your details</h3>
        {field('Name', profile.name)}
        {field('Email', profile.email)}
        {profile.subcontractor && (
          <>
            {field('Company name', profile.subcontractor.companyName)}
            {field('ABN', profile.subcontractor.abn)}
            {field('Vendor number', profile.subcontractor.vendorNumber)}
            {field('Email address', profile.email)}
            {field('Phone', profile.phone || 'Not specified')}
          </>
        )}
      </div>
    </>
  )
}
