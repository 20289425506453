import { useContext } from 'react'
import { Job, User } from '../../../server/src/types'
import { Auth, authContext } from './auth'
import { API_ROOT } from '../../env'

export type LoginResponse =
  | {
      success: true
      id: string
      name: string
      email: string
      token: string
    }
  | {
      success: false
      reason: string
    }

class ApiService {
  constructor(private auth: Auth) {}

  get user() {
    return this.auth.user
  }

  async login(email: string, password: string): Promise<LoginResponse> {
    try {
      const resp = await fetch(`${API_ROOT}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })

      switch (resp.status) {
        case 200:
          const json = await resp.json()
          const result = {
            success: true as true,
            id: json.id,
            name: json.name,
            email: json.email,
            token: json.token,
            role: json.role,
          }
          this.auth.setUser(result)
          return result
        case 401:
          return {
            success: false,
            reason: 'Incorrect email or password',
          }
      }
    } catch (err) {}

    return {
      success: false,
      reason: 'Unknown error occured',
    }
  }

  async resetPassword(email: string): Promise<void> {
    await fetch(`${API_ROOT}/password-reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
  }

  async completePasswordReset(
    email: string,
    password: string,
    token: string
  ): Promise<{ success: boolean; message: string }> {
    const resp = await fetch(`${API_ROOT}/password-reset/complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, token }),
    })
    return { success: resp.ok, message: await resp.text() }
  }

  async logout(): Promise<void> {
    this.authFetch(`${API_ROOT}/logout`, { method: 'POST' }).catch(() => {})
    localStorage.setItem('loggedOut', 'true')
    this.auth.removeUser()
  }

  async getAvailableJobs(): Promise<Job[]> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/available`)
    if (resp.status !== 200) {
      throw new Error(`API Error`)
    }
    return (await resp.json()).jobs
  }

  async getMyJobs(): Promise<Job[]> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/mine`)
    if (resp.status !== 200) {
      throw new Error(`API Error`)
    }
    return (await resp.json()).jobs
  }

  async getAllJobs(): Promise<Job[]> {
    const resp = await this.authFetch(`${API_ROOT}/jobs`)
    if (resp.status !== 200) {
      throw new Error(`API Error`)
    }
    return (await resp.json()).jobs
  }

  async getJob(id: string): Promise<Job | undefined> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/${id}`)
    if (resp.status == 404) {
      return undefined
    }
    if (resp.status !== 200) {
      throw new Error(`API Error`)
    }
    return await resp.json()
  }

  async acceptJob(id: string): Promise<boolean> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/${id}/accept`, { method: 'POST' })
    return resp.ok
  }

  async cancelJob(id: string, reason: string): Promise<boolean> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/${id}/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ reason }),
    })
    return resp.ok
  }

  async completeJob(id: string): Promise<boolean> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/${id}/complete`, { method: 'POST' })
    return resp.ok
  }

  async uploadFile(file: File): Promise<string | undefined> {
    const data = new FormData()
    data.append('file', file)
    const resp = await this.authFetch(`${API_ROOT}/uploads`, {
      method: 'POST',
      body: data,
    })
    return resp.ok ? (await resp.json()).filename : undefined
  }

  async closeJob(id: string, jobSheet: string, invoice: string): Promise<boolean> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/${id}/close`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ jobSheet, invoice }),
    })
    return resp.ok
  }

  async createJob(data: JobInput): Promise<string | undefined> {
    const resp = await this.authFetch(`${API_ROOT}/jobs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (resp.ok) {
      const json = await resp.json()
      return json.id
    }
    return undefined
  }

  async editJob(id: string, data: JobInput): Promise<boolean> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    return resp.ok
  }

  async setJobRating(id: string, rating: number): Promise<boolean> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/${id}/rating`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ rating }),
    })
    return resp.ok
  }

  async deleteJob(id: string): Promise<boolean> {
    const resp = await this.authFetch(`${API_ROOT}/jobs/${id}`, { method: 'DELETE' })
    return resp.ok
  }

  async getUser(id: string): Promise<User> {
    const resp = await this.authFetch(`${API_ROOT}/users/${id}`)
    if (resp.status !== 200) {
      throw new Error(`API Error`)
    }
    return await resp.json()
  }

  getProfile(): Promise<User> {
    if (this.user) {
      return this.getUser(this.user.id)
    } else {
      throw new Error(`Not logged in`)
    }
  }

  async createUser(data: UserInput): Promise<User | undefined> {
    const resp = await this.authFetch(`${API_ROOT}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (resp.ok) {
      const json = await resp.json()
      return json.id
    }
    return undefined
  }

  async editUser(id: string, data: UserInput): Promise<boolean> {
    const resp = await this.authFetch(`${API_ROOT}/users/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    return resp.ok
  }

  async deleteUser(id: string): Promise<boolean> {
    const resp = await this.authFetch(`${API_ROOT}/users/${id}`, { method: 'DELETE' })
    return resp.ok
  }

  async getUsers(): Promise<User[]> {
    const resp = await this.authFetch(`${API_ROOT}/users`)
    if (resp.status !== 200) {
      throw new Error(`API Error`)
    }
    return (await resp.json()).users
  }

  private async authFetch(input: RequestInfo, init?: RequestInit) {
    if (!this.auth.user) {
      throw new Error('Not authenticated')
    }
    const { token } = this.auth.user
    const resp = await fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${token}`,
      },
    })
    if (resp.status == 401) {
      this.auth.removeUser()
      throw new Error('Not authenticated')
    }
    return resp
  }
}

export function useApi() {
  const auth = useContext(authContext)
  return new ApiService(auth)
}

export { Job, User }

export type JobInput = Pick<Job, 'title' | 'address' | 'customer' | 'description' | 'jobNumber' | 'time'>
export type UserInput = Pick<User, 'name' | 'email' | 'phone' | 'role' | 'subcontractor'>
