import React from 'react'
import './Spinner.scss'

interface SpinnerProps {
  margin?: number
  inline?: boolean
}

export function Spinner(props: SpinnerProps) {
  const display = props.inline ? 'inline-block' : undefined
  const margin = `${props.margin ?? 20}px auto`
  return (
    <div className="loadingio-spinner-double-ring-s9p5c557v9c" style={{ margin, display }}>
      <div className="ldio-1sfuewhscwt">
        <div></div>
        <div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  )
}